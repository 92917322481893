import { motion } from "framer-motion"
import TransitionLink from 'gatsby-plugin-transition-link'
import React, { useEffect, useRef, useState } from 'react'
import Slick from "react-slick"
import Bread from '../components/bread'
import Button from '../components/buttons/button_goto'
import Formularz from '../components/formularz/formularz'
import Seo from '../components/seo.js'
import Profil from '../components/zespol/profil'
import paluch from '../images/ikony/paluch.svg'
import biuro_d from '../images/onas/biuro_desktop.jpg'
import biuro_m from '../images/onas/biuro_mobile.jpg'
import intro from '../images/podstrony/onas/intro.jpg'
import kompetencje from '../images/podstrony/onas/kompetencje.jpg'
import kompetencjeMobile from '../images/podstrony/onas/kompetencjeMobile@2x.jpg'
import Kamil from '../images/podstrony/onas/skuat/kamil.jpg'
import Marta from '../images/podstrony/onas/skuat/marta.jpg'
import Tomek from '../images/podstrony/onas/skuat/tomek.jpg'
import Martaw from '../images/podstrony/onas/skuat/martaw.png'
import Magda from '../images/podstrony/onas/skuat/magda.png'
import onas from '../images/seo/onas.jpg'


const calc2 = o => `translateY(${o * -0.07}px)`
const calc = o => `translateY(${o * 0.03}px)`




export default function Onas({transitionStatus, entry,}) {


  const [czyMobile, ustawMobile] = useState(false);
  var kompetencjeFinal = null;

  const ref = useRef();
  const szer = useRef();
  const main_tag = useRef();

  useEffect(() => {
    console.log('width', szer.current ? szer.current.offsetWidth : 0);

    if(szer.current.offsetWidth < 1025) {
      ustawMobile(true)
    }
    else {
      ustawMobile(false)
    }
  
  }, [szer.current]);

  
  let settings = {
    dots: false,
    infinite: false,
    arrows: false,
    variableWidth: false,
    speed: 300,

 
    slidesToShow: 3.2,

    responsive: [
      {
          breakpoint: 1025,
          settings: {
            slidesToShow: 1.1,
            centerMode: false,
        
 
            variableWidth: false,
            infinite: false,

    
          }
        },

  ],
  }
  


  const main = useRef();
  const title = 'O NAS'

  return (

    <>
      <div
      ref={szer}
      style={{
        width: '100vw',
        position: 'fixed',
        zIndex: '-10000'
      }}></div>
      <motion.div 
      initial={entry.state}
      ref={main}
      animate={
        (transitionStatus === "exiting" && entry.state.past !== "/")   ? {x: -entry.state.len} :  {x: 0}
      }
      transition={{duration: 0.8,
      }}
       >
      <Seo title={title} description="Rozmywamy granice między sztuką i biznesem, skupiając się na ludziach i ich doświadczeniach. Projektujemy wyjątkowe marki." image={onas} />
      <Bread 
      tekst1="WSPÓŁPRACA" link1="/formularz" tekst2="PROJEKTY" link2="https://www.instagram.com/odrastudio/"
      title={title} />
      
        
        <div style={{zIndex: '1'}} className="podstrona-wrapper onas-podstrona">
        <img style={{width: '100%'}} src={intro} />
          <div className="onas-tekst">
          <h6>Jak strategicznie myśleć</h6>
          <h4>Jesteśmy wrocławskim studiem kreatywnym. Nasz zespół składa się ze specjalistów od marketingu, projektowania graficznego i mediów. Rozmywamy granice między sztuką i biznesem, skupiając się na ludziach i ich doświadczeniach. Dzięki strategicznemu podejściu, wyrazistej kreacji i precyzyjnemu wdrożeniu na rynek, angażujemy i budujemy więzi z użytkownikami. Skontaktuj się z nami - zaopiekujemy się Twoją marką!  </h4>
                </div>




          <div className="onas-kompetencje">
            <div>
              <h6>KOMPETENCJE</h6>
              <p>Mamy zróżnicowany kompetencyjnie zespół i bardzo zdolnych partnerów! Zobacz gdzie czujemy się jak ryba w wodzie:</p>
            </div>


            <TransitionLink
                state={{ title: 'USŁUGI' }}
                activeClassName="podstrona-aktywna"
                to="/uslugi"
                partiallyActive={true}
                exit={{
                  length: 1,
                  state: { x: -entry.state.len},
                }}
                entry={{
                  length: 1,             
                  state:  {x: (entry.state.len), len: entry.state.len},
                }}

              >
            <div className='button-goto'>
              <img src={paluch} alt="Przejdź do/Zobacz więcej" />
              <span className="przycisk">USŁUGI</span>
            </div>
            </TransitionLink>
          </div>
          <img style={{width: '100%'}} src={czyMobile ? kompetencjeMobile : kompetencje} />
          

          <div className="sekcja-padding">
          <h6 style={{marginBottom: '10px'}}>Zespół</h6>
          <Slick  className="zespol-slider" {...settings}>

            <Profil
                alt="Tomasz Charęza"
                imie="Tomasz Charęza"
                stanowisko="CEO"
                img={Tomek}
                     />
              <Profil
                alt="Kamil Lach"
                imie="Kamil Lach"
                stanowisko="Dyrektor artystyczny"
                img={Kamil}
                     />

                     <Profil
                alt="Marta Szymczak"
                imie="Marta Szymczak"
                stanowisko="Menadżerka projektów"
                img={Marta}
                     />     
                      <Profil
                alt="Marta Wiewiórowska"
                imie="Marta Wiewiórowska"
                stanowisko="Grafik"
                img={Martaw}
                     /> 
                     <Profil
                alt="Magdalena Kucharska"
                imie="Magdalena Kucharska"
                stanowisko="Brand menadżerka"
                img={Magda}
                     />     
                       
          </Slick>
          </div>

          <div className="onas-dolacz">


          


              <div>
              
              <h6 style={{marginBottom: '10px'}}>Dołącz do zespołu</h6>

              <h4>Stale rozwijamy nasz zespół - zarówno stacjonarny jak i zaplecze freelancerów. Skontaktuj się  z nami za pomocą  formularza, wyślij swoje CV, portfolio i daj znać  co możesz wnieść  do naszej organizacji oraz czego oczekujesz po współpracy z nami. </h4>


              <TransitionLink
                state={{ title: 'KONTAKT' }}
                activeClassName="podstrona-aktywna"
                partiallyActive={true}
                to="/formularz"
             

              >
              <Button text="POROZMAWIAJMY" />
              </TransitionLink>


            </div>
          </div>

          {czyMobile ? <img style={{width: '100%', marginTop: '30px'}} src={biuro_m} /> : <img style={{width: '100%'}}  src={biuro_d} />}


          

        </div>
   

      <Formularz />


      </motion.div>


    </>

  )
}
