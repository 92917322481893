import React from 'react'



class Profil extends React.Component {
  constructor() {
    super()
    this.state = { showR: false }
   
  }
 

  render() {

    return <div className='profil-wrapper'
   
       >
               <img src={this.props.img} alt={this.props.alt} />
               <span className="przycisk" alt={this.props.alt}>{this.props.imie}</span>
               <h6>{this.props.stanowisko}</h6>
              
           </div>
      }
}

export default Profil
